import React from 'react';

import { Swiper, SwiperSlide } from 'swiper/react';

import 'swiper/css';
import 'swiper/css/pagination';
import '../swiper.css';

import { Autoplay, Pagination } from 'swiper';

// import skill data
import { skills } from '../data';

const Skills = () => {
  return (
    <>
    <Swiper
    speed={2000}
     pagination={{
      clickable: true,
    }}
    autoplay={{
      delay: 3000,
      disableOnInteraction: false,
      
    }}
    modules={[Autoplay, Pagination]}
    className='mySwiper'
    
    >
    <section className='bg-gray-400 py-8 '>
     
      <div className=' mx-auto '>
        <div
          className='grid grid-cols-5 md:grid-flow-col '
        >
          {skills.map((skill, index) => {
            return (
              <SwiperSlide key={index}>
              <div
                className='flex items-center flex-col justify-center px-5 py-2 lg:h-96 md:h-96 lg:flex-row'
                key={index}
              >
                <img className=' h-full max-h-96   shrink ' src={skill.image} alt='products' />
                <p className=''>{skill.des}</p>
              </div>
              </SwiperSlide>
            );
          })}
        </div>
      </div>
    </section>
    </Swiper>
    </>
  );
};

export default Skills;

import React from 'react';

// import brands data
import { brands } from '../data';

const Brands = () => {
  return (
    <section className='h-screen  bg-gradient-to-b from-gray-100 via-gray-900to-black min-h-[146px] lg:bg-gradient-to-r from-gray-100 via-gray-900 to-black flex items-center  p-3 lg:h-full'>
      <div
        className=' w-full mx-auto flex flex-col justify-around h-full  items-center flex-wrap  lg:flex-row'
      >
        {brands.map((brand, idx) => {
          return (
            <div
              key={idx}
            >
              
              <a href={brand.href} target="_blank" rel="noreferrer">
              <img src={brand.img} alt={brand.alt} className=' max-w-sm max-h-16 cursor-pointer hover:scale-105'/>
              </a>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default Brands;

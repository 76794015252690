import React from 'react';

// import img
import Image from '../assets/img/two4two/aboutImg.jpg';
import Image02 from '../assets/img/two4two/kalin.jpg';

const About = () => {
  return (
    <section className='section bg-primary' id='about'>
      <div className='container mx-auto'>
        <div className='flex flex-col xl:flex-row gap-24'>
          <img
            className='object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded lg:h-2/4'
            src={Image}
            alt=''
          />
          <div className='  flex flex-col items-center text-center lg:items-start lg:text-left'>
            <div className='flex flex-col'>
              <h2 className='text-3xl lg:text-4xl font-medium lg:font-extrabold mb-3 before:content-about relative before:absolute before:opacity-40 before:-top-[2rem] before:hidden before:lg:block'>
                Ποιοί είμαστε
              </h2>
              <p className='mb-4 text-accent'>
              Ειρήνη Kαμπά & Γιώργος Ποιμενάκης
              </p>
              <hr className='mb-8 opacity-5' />
              <p className='mb-8'>
              Καλώς ήρθατε στο κομμωτήριό μας! Το κομμωτήριό μας είναι ένας ζεστός και φιλόξενος χώρος όπου μπορείτε να χαλαρώσετε και να μας αφήσετε να φροντίσουμε τις ανάγκες περιποίησης των μαλλιών σας.<br />
                <br />
                Η ομάδα μας είναι αφοσιωμένη στο να ενημερώνεται για τα πιο σύγχρονα στυλ και τεχνικές και προσφέρουμε ένα ευρύ φάσμα υπηρεσιών για να καλύψει όλες τις ανάγκες περιποίησης των μαλλιών σας.
              </p>

              <img
            className='object-cover h-full w-[566px] md:mx-auto lg:mx-0 rounded lg:h-3/4'
            src={Image02}
            alt='κομμωτήριο'
          />
            </div>
           
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;

import React from 'react';

// import social data
import { social } from '../data';
import girl from '../assets/img/two4two/hero-image.png';




const Footer = () => {
  return (
    <footer className='bg-tertiary py-12'>
      <div className='container mx-auto'>
        <div className='flex flex-col lg:flex-row space-y-6 lg:space-y-0 items-center justify-between'>
        <img src={girl} alt="" className='w-20 h-20 ml-7'/>
          <div className='flex space-x-6 items-center justify-center'>
            {social.map((item, index) => {
              const { href, icon } = item;
              return (
                <a className='text-accent text-base' href={href} key={index} target="_blank" rel="noreferrer">
                  {icon}
                </a>
              );
            })}
          </div>
         
         
          
        
          <p className='text-white  text-[15px]'>
           <span className='text-white'> &copy; 2022</span> Kostandinos Vasili. <small className='text-gray-400'> All rights reserved.</small>
          </p>
        </div>
      </div>
    </footer>
  );
};

export default Footer;

//  icons
import {
  FiInstagram,
  FiFacebook,
  FiMail,
  FiMapPin,
 
} from 'react-icons/fi';
import { BsTelephoneForwardFill } from 'react-icons/bs';

// companies icons
// import FreelancerBrandIcon from './assets/img/two4two/icon.png';
import Mua from './assets/img/logos/muaLogo.png';
import Lavish from './assets/img/logos/lavishLogo02.jpg';
import Moro from './assets/img/logos/moroLogo.webp';
import Kyana from './assets/img/logos/kyanaLogo.jpeg';
import Mowan from './assets/img/logos/mowan-logo-white.png';
import Redken from './assets/img/logos/redkenLogo.png';
import Megix from './assets/img/logos/megix-10.png';
import Monplatin from './assets/img/logos/monplatin.png';
import IconCosmetics from './assets/img/logos/cosmetics.png';




// projects images
import Project8 from './assets/img/gallery/image05.jpg';
import Project1 from './assets/img/gallery/image37.jpg';
import Project11 from './assets/img/gallery/image01.jpg';
import Project4 from './assets/img/gallery/image39.jpg';
import Project5 from './assets/img/gallery/image03.jpg';
import Project6 from './assets/img/gallery/image47.jpg';
import Project3 from './assets/img/gallery/image15.jpg';
import Project10 from './assets/img/gallery/image20.jpg';
import Project9 from './assets/img/gallery/image24.jpg';
import Project7 from './assets/img/gallery/image32.jpg';
import Project2 from './assets/img/gallery/image25.jpg';
import Project12 from './assets/img/gallery/image44.jpg';

// skills images
import Biobotanic from './assets/img/brands/biobotanic02.jpeg';
import brazilian from  './assets/img/brands/brazilian.webp';
import ikon from  './assets/img/brands/ikon.jpg';
import LavishKids from './assets/img/brands/lavishKids.jpg';
import fanola from './assets/img/brands/fannolla.jpg';
import moro from './assets/img/brands/mo_treatment.webp';
import cream from './assets/img/brands/megix001.png';
import bm from './assets/img/brands/hy.jpg';
import dreamCoat from './assets/img/brands/DreamCoat.webp';



// testimonial images
import TestiImage1 from './assets/img/testimonials/kostras.webp';
import TestiImage2 from './assets/img/testimonials/testimonial-2.webp';
import TestiImage3 from './assets/img/testimonials/testimonial-3.webp';


import manServices from './assets/img/services/adras.png'
import xtenisma from './assets/img/services/xtenisma.png'
import womanServices from './assets/img/services/woman-hair.png'
import therapeia from './assets/img/services/hair.png'


// navigation
export const navigation = [
  {
    name: 'ΑΡΧΙΚΗ',
    href: 'home',
  },
  {
    name: 'ΕΜΕΙΣ',
    href: 'about',
  },
  {
    name: 'ΔΟΥΛΕΙΕΣ',
    href: 'portfolio',
  },
  {
    name: 'ΥΠΗΡΕΣΙΕΣ',
    href: 'services',
  },
  {
    name: 'ΚΡΙΤΙΚΕΣ',
    href: 'testimonials',
  },
  {
    name: 'ΕΠΙΚΟΙΝΩΝΙΑ',
    href: 'contact',
  },
];

// social
export const social = [
 
  {
    icon: <FiInstagram />,
    href: 'https://www.instagram.com/explore/locations/1956125277945535/two-for-two-ladies-gents',
  },
  {
    icon: <FiFacebook />,
    href: 'https://www.facebook.com/Two4TwoLadiesAndGents/',
  },
 
];

// companies
export const brands = [
  
  {
    img: Redken,
    href: 'https://www.redken.eu/el-gr',
    alt: "redken"
  },
  {
    img: Monplatin,
    href: 'https://mppro.gr/',
    alt: "mono platin"
  },
  {
    img: Mua,
    href: 'https://www.muagreece.gr/',
    alt: "mua greece"
  },
 
  {
    img: Moro,
    href: 'https://www.moroccanoil.com/us_en/',
    alt: "moroccanoil"
  },
  
  
  {
    img: Mowan,
    href: 'https://www.mowan.it/',
    alt: "mowan"
  },
  {
    img: Kyana,
    href: 'https://www.kyana.gr/',
    alt: "kyana"
  },
  {
    img: Megix,
    href: 'https://www.mowan.it/hair-colouring-system-megix_10/',
    alt: "megix-10"
  },
  {
    img: Lavish,
    href: 'https://lavishcare.eu/',
    alt: "lavish"
  },
 
  
  {
    img: IconCosmetics,
    href: 'https://icon-cosmetics.gr/',
    alt: "icon-cosmetics"
  },
 
 
];

// projects
export const projectsData = [
  {
    id: '1',
    image: Project1,
    name: 'project name 1',
    category: 'Γυναικείο',
  },
  {
    id: '2',
    image: Project2,
    name: 'project name 2',
    category: 'Γυναικείο',
  },
  {
    id: '3',
    image: Project3,
    name: 'project name 3',
    category: 'Γυναικείο',
  },
  {
    id: '4',
    image: Project4,
    name: 'project name 4',
    category: 'Γυναικείο',
  },
  {
    id: '5',
    image: Project5,
    name: 'project name 5',
    category: 'Ανδρικό',
  },
  {
    id: '6',
    image: Project6,
    name: 'project name 6',
    category: 'Γυναικείο',
  },
  {
    id: '7',
    image: Project7,
    name: 'project name 7',
    category: 'Γυναικείο',
  },
  {
    id: '8',
    image: Project8,
    name: 'project name 8',
    category: 'Ανδρικό',
  },
  {
    id: '9',
    image: Project9,
    name: 'project name 9',
    category: 'Γυναικείο',
  },
  {
    id: '10',
    image: Project10,
    name: 'project name 10',
    category: 'Γυναικείο',
  },
  {
    id: '11',
    image: Project11,
    name: 'project name 11',
    category: 'Ανδρικό',
  },
  {
    id: '12',
    image: Project12,
    name: 'project name 12',
    category: 'Γυναικείο',
  },
];

// projects
export const projectsNav = [
  {
    name: 'all',
  },
  {
    name: 'Ανδρικό',
  },
  {
    name: 'Γυναικείο',
  },
 
];

// skill
export const skills = [
  {
    image: cream,
    des: "Megix 10 Hair Colour Cream is much more than a simple coloring, repairs, moisturizes and nourishes the hair fiber giving it new life and beauty."
  },
  {
    image: Biobotanic,
    des: "Magic conditioner για βαμμένα, ταλαιπωρημένα και ξηρά μαλλιά Βιολογικό λάδι καρύδας και βιολογικό βούτυρο καριτέ."
  },
  {
    image: brazilian,
    des: "Βrazilian Βond Βuilder"
  },
  {
    image: fanola,
    des: "Σαμπουάν εξουδετέρωσης για ξανθά και μαλλιά με ανταύγιες για γυναίκες."
  },
  {
    image: ikon,
    des: "Ενδυνάμωση Μαλλιών."
  },
 
  {
    image: bm,
    des: "monplatin Greece Περιποίηση Μαλλιών."
  },
 
  {
    image: LavishKids,
    des: "Παιδικό Αφρόλουτρο για τα μαλλιά και το σώμα"
  },
 
  {
    image: moro,
    des: "Το Moroccanoil Treatment μπορεί να χρησιμοποιηθεί ως εργαλείο περιποίησης, styling και φινιρίσματος."
  },
  {
    image: dreamCoat,
    des: "Η θεραπεία κατά του φριζαρίσματος αδιαβροχοποιεί τα μαλλιά σας για να σταματήσει το φριζάρισμα, ακόμη και στη χειρότερη υγρασία"
  },

];

// services
export const services = [
  {
    icon: manServices,
    name: 'ΚΟΥΡΕΜΑ (Ανδρικό, Γυναικείο, Παιδικό)',
    description:
      'Στο κομμωτήριό μας, προσφέρουμε μια μεγάλη ποικιλία από στυλ & κουρέματα που ταιριάζουν σε όλα τα σχήματα προσώπου, τύπους μαλλιών και προσωπικές προτιμήσεις.',
  },
  {
    icon: xtenisma,
    name: 'ΧΤΕΝΙΣΜΑ',
    description:
      'Στο κομμωτήριό μας, προσφέρουμε μια σειρά από υπηρεσίες ισιώματος μαλλιών που ταιριάζουν σε όλους τους τύπους μαλλιών και τις προσωπικές προτιμήσεις.',
  },
  {
    icon: womanServices,
    name: 'ΒΑΦΗ',
    description:
      'Η ομάδα  μας έχει εκπαιδευτεί στις πιο πρόσφατες τεχνικές χρωματισμού, όπως full color, highlights, balayage και πολλά άλλα. Χρησιμοποιούμε μόνο υψηλής ποιότητας προϊόντα βαφής μαλλιών για να διασφαλίσουμε ότι το χρώμα σας είναι ζωντανό και μακράς διαρκείας.',
  },
  {
    icon: therapeia,
    name: 'ΘΕΡΑΠΕΙΑ',
    description:
      'Οι θεραπείες μπορούν να βοηθήσουν στη θρέψη και την ενυδάτωση των μαλλιών και του τριχωτού της κεφαλής και μπορεί να είναι ιδιαίτερα ευεργετικές για άτομα με ξηρά, κατεστραμμένα ή χημικά επεξεργασμένα μαλλιά.',
  },
  
];

// testimonials
export const testimonials = [
  {
    authorImg: TestiImage1,
    authorText:
      'Το αγαπημένο μου κομμωτήριο για το καλύτερο στυλ μαλλιών! Δεν είναι μόνο προσιτό αλλά και εκπληκτικό κούρεμα. Εξαιρετικά προϊόντα μαλλιών και το ραντεβού ξεκινάει στην ώρα του! Πηγαίνω εκεί για πολλά χρόνια και δεν έχω απογοητευτεί ποτέ. Συνιστάται ανεπιφύλακτα!',
    authorName: 'Κωνσταντίνος Β.',
    authorPosition: '',
  },
  {
    authorImg: TestiImage2,
    authorText:
      'Η Ειρήνη έκανε τα μαλλιά μου και μου άρεσε. Ήταν φοβερή, ευγενική και γλυκιά. Έκανε τα μαλλιά μου ακριβώς όπως τα ήθελα. Σίγουρα θα ξαναπάω κοντά της.',
    authorName: 'Δήμητρα Κ.',
    authorPosition: '',
  },
  {
    authorImg: TestiImage3,
    authorText:
      'Έχω πάει εκεί αρκετές φορές και πάντα ήμουν ευχαριστημένη. Η ατμόσφαιρα είναι εξαιρετικά προσγειωμένη, νιώθεις ότι σε ακούν και το προσωπικό τους παίρνει την τέχνη τους στα σοβαρά.',
    authorName: 'Βασιλική Τ.',
    authorPosition: '',
  },
];

// contact
export const contact = [
  {
    icon: <FiMail />,
    title: 'Έχετε ερωτήσεις?',
    subtitle: 'Είμαστε εδώ για εσάς.',
    description: 'Email  eirinikampa88@gmail.com',
  },
  {
    icon: <FiMapPin />,
    title: 'Τοποθεσία',
    subtitle: 'Ηράκλειο, Κρήτης',
    description: 'Μιλτιάδου 13 & Μ.Κωνσταντίνου 37 (Πατέλες)',
  },
  {
    icon: <BsTelephoneForwardFill />,
    
    subtitle: 'Tηλέφωνο:',
    description: '2814002222',
  },
];

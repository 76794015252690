import React from 'react';

// import woman image
import WomanImg from '../assets/img/two4two/banner04.png';
import Logo from "../assets/img/logos/242Logo.png"
import { BsTelephoneForwardFill } from 'react-icons/bs';
import {FiMapPin} from "react-icons/fi"

const Hero = () => {
  return (
    <section
      id='home'
      className='lg:h-[85vh] flex items-center bg-gradient-to-t from-gray-400 via-gray-500 to-gray-600 lg:bg-cover lg:bg-center lg:bg-no-repeat py-32 lg:py-0 overflow-hidden'
    >
      <div className='container mx-auto  h-full '>
        <div className='flex items-center h-full pt-8 justify-evenly'>
          <div className='flex-1 flex flex-col   items-center lg:items-start'>
           
            <h1 className='font-mono text-4xl leading-[44px] md:text-5xl md:leading-tight lg:text-7xl lg:leading-[1.2] font-bold md:tracking-[-2px] text-transparent bg-clip-text bg-gradient-to-r from-gray-100 to bg-gray-900 drop-shadow-lg shadow-black'>
            ΚΟΜΜΩΤΗΡΙΟ
            </h1>
         
            
           <img src={Logo} alt="logo" className='mb-5 mt-5'/>
            <div className='flex flex-col gap-4 lg:flex-row  p-4'>
            
            <button className='btn  gap-3 rounded-md btn-md bg-gradient-to-r from-green-600  to-green-500 hover:text-lg md:btn-lg transition-all'>
             <span><BsTelephoneForwardFill /></span> <a href='tel:+302814002222'>Καλέστε μας</a>
            </button>
            
            <button className='btn   gap-3 rounded-md btn-md bg-gradient-to-r from-blue-600  to-blue-500 hover:text-lg md:btn-lg transition-all'>
             <span><FiMapPin /></span> <a href='https://goo.gl/maps/CtUxjZm3umxAaoCp8' target="_blank" rel="noreferrer">Βρείτε μας</a>
            </button>
            </div>
            <p className='text-white'>Μιλτιάδου 13, Ηράκλειο Κρήτης</p>
          </div>
          <div className='hidden lg:flex  justify-center items-center h-full ml-5'>
            <img src={WomanImg} alt='hairs' className='rounded ml-5 p-5 w-screen'/>
          </div>
        </div>
      
      </div>
    </section>
  );
};

export default Hero;

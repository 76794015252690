import React from 'react'

import video01 from '../assets/img/gallery/video01_Trim.mp4';

function Videos() {
  return (
    <div className='bg-primary'>
    <video  
    style={{ maxWidth: "100%", width: "800px", margin: "0 auto" }}
    playsInline
    loop
    muted
    alt="All the devices"
    src={video01}
    autoPlay={true}
    >
   
    <source src={video01} type="video/mp4"/>
    Sorry, your browser doesn't support videos.
  </video>
    </div>
  )
}

export default Videos